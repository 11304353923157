var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "van-tab",
                { attrs: { name: "info", title: "入库计划" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "关联订单" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "关联订单",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.orderName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "订单编号",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.orderNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "关联主合同",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.contractName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "合同编号",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.contractNumber
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "委托客户名称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.dsEnterpriseName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "贸易执行企业名称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.tradeOrgName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "供应商名称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.usEnterpriseName
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "入库计划" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "入库计划编号",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.planInNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "仓库地点",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.warehousePlaceNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "仓库名称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.warehouseName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "仓库地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.inWarehousingDetail.addressProvince,
                            _vm.inWarehousingDetail.addressCity,
                            _vm.inWarehousingDetail.addressXq,
                            _vm.inWarehousingDetail.addressDetail
                          ])
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "入库类型",
                          value: _vm.utils.statusFormat(
                            _vm.inWarehousingDetail.inType,
                            "InType"
                          )
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "van-common-detail" },
                        [
                          _c("van-cell", [
                            _c(
                              "div",
                              { staticClass: "goods-table" },
                              _vm._l(
                                _vm.inWarehousingDetail.containerList,
                                function(item, idx) {
                                  return _c(
                                    "table",
                                    {
                                      key: item.id,
                                      staticClass: "inner-goods-table",
                                      attrs: { border: "1" }
                                    },
                                    [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "header-th",
                                            attrs: { colspan: "2" }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(idx + 1))
                                            ])
                                          ]
                                        )
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("柜号")]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.cabinetNo))
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("国家")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.utils.statusFormat(
                                                item.pickUpInfoVo.country,
                                                "Country"
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("原产地")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              item.pickUpInfoVo.countryOfOrigin
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("HS编码")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(item.pickUpInfoVo.hsCode)
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("货物名称")]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.cargoName))
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("箱型")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(item.pickUpInfoVo.boxType)
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("件数")]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.pieceNumber))
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("重量（KG）")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              item.pickUpInfoVo.actualWeight
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("总金额")]),
                                        _c("td", [_vm._v(_vm._s(item.money))])
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("操作")]),
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click:
                                                  _vm.receiptPlanApprovalDetail
                                              }
                                            },
                                            [_vm._v("查看详情")]
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        ],
                        1
                      ),
                      _c("van-cell", {
                        attrs: {
                          title: "预计到库日期",
                          value: _vm.utils.dateTimeFormat(
                            _vm.inWarehousingDetail.expectDateStart
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "发运计划" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "运输方式",
                          value: _vm.utils.statusFormat(
                            _vm.inWarehousingDetail.transportType,
                            "LogisticsMode"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "车数",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.inWarehousingDetail.vehiclesNum
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "发运日期",
                          value: _vm.utils.dateTimeFormat(
                            _vm.inWarehousingDetail.shipmentDateStart
                          )
                        }
                      }),
                      _c("div", { staticClass: "table goods-table" }, [
                        _c("table", { staticClass: "inner-goods-table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("车牌号")]),
                              _c("th", [_vm._v("联系人")]),
                              _c("th", [_vm._v("联系电话")]),
                              _c("th", [_vm._v("驾驶证号或身份证号")]),
                              _c("th", [_vm._v("备注")])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.inWarehousingDetail.vehiclesList,
                              function(item) {
                                return _c("tr", { key: item.materialId }, [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.utils.isEffectiveCommon(
                                          item.numberPlates
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.utils.isEffectiveCommon(
                                          item.contacts
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.utils.isEffectiveCommon(item.phone)
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.utils.isEffectiveCommon(
                                          item.certificates
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.utils.isEffectiveCommon(
                                          item.remarks
                                        )
                                      )
                                    )
                                  ])
                                ])
                              }
                            ),
                            0
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "audit", title: "审批意见" } },
                [
                  Number(_vm.$route.query.taskViewMode) !== 0
                    ? _c("workflow-audit", {
                        attrs: {
                          "page-key": "stock_in_plan",
                          "business-key": _vm.inWarehousingDetail.stockInId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          variable: {
                            businessId: _vm.inWarehousingDetail.stockInId,
                            motif:
                              _vm.inWarehousingDetail.cargoNames +
                              "-" +
                              _vm.inWarehousingDetail.warehouseName
                          },
                          "view-mode": true
                        },
                        on: {
                          confirmAudit: function($event) {
                            return _vm.utils.backReturn(
                              _vm.$route.query.messageType
                            )
                          }
                        }
                      })
                    : _c("workflow-audit", {
                        attrs: {
                          "page-key": "stock_in_plan",
                          "business-key": _vm.inWarehousingDetail.stockInId,
                          "task-id": _vm.$route.query.taskId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          variable: {
                            businessId: _vm.inWarehousingDetail.stockInId,
                            motif:
                              _vm.inWarehousingDetail.cargoNames +
                              "-" +
                              _vm.inWarehousingDetail.warehouseName
                          }
                        },
                        on: {
                          confirmAudit: function($event) {
                            return _vm.utils.backReturn(
                              _vm.$route.query.messageType
                            )
                          }
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.utils.backReturn(_vm.$route.query.messageType)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
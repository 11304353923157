<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <van-tabs v-model="activeName">
        <van-tab name="info" title="入库计划">
          <van-cell-group title="关联订单">
            <van-cell title="关联订单" :value="utils.isEffectiveCommon(inWarehousingDetail.orderName)" />
            <van-cell title="订单编号" :value="utils.isEffectiveCommon(inWarehousingDetail.orderNo)" />
            <van-cell title="关联主合同" :value="utils.isEffectiveCommon(inWarehousingDetail.contractName)" />
            <van-cell title="合同编号" :value="utils.isEffectiveCommon(inWarehousingDetail.contractNumber)" />
            <van-cell title="委托客户名称" :value="utils.isEffectiveCommon(inWarehousingDetail.dsEnterpriseName)" />
            <van-cell title="贸易执行企业名称" :value="utils.isEffectiveCommon(inWarehousingDetail.tradeOrgName)" />
            <van-cell title="供应商名称" :value="utils.isEffectiveCommon(inWarehousingDetail.usEnterpriseName)" />
          </van-cell-group>
          <van-cell-group title="入库计划">
            <van-cell title="入库计划编号" :value="utils.isEffectiveCommon(inWarehousingDetail.planInNo)" />
            <van-cell title="仓库地点" :value="utils.isEffectiveCommon(inWarehousingDetail.warehousePlaceNo)" />
            <van-cell title="仓库名称" :value="utils.isEffectiveCommon(inWarehousingDetail.warehouseName)" />
            <van-cell title="仓库地址" :value="utils.newProvinceCityFormatter([inWarehousingDetail.addressProvince,inWarehousingDetail.addressCity, inWarehousingDetail.addressXq, inWarehousingDetail.addressDetail])" />
            <van-cell title="入库类型" :value="utils.statusFormat(inWarehousingDetail.inType, 'InType')" />
            <div class="van-common-detail">
              <van-cell>
                <div class="goods-table">
                  <table v-for="(item,idx) in inWarehousingDetail.containerList" :key="item.id" class="inner-goods-table" border="1">
                    <tr>
                      <th colspan="2" class="header-th">
                        <span>{{ idx+1 }}</span>
                      </th>
                    </tr>
                    <tr>
                      <td>柜号</td><td>{{ item.cabinetNo }}</td>
                    </tr>
                    <tr>
                      <td>国家</td><td>{{ utils.statusFormat(item.pickUpInfoVo.country,'Country') }}</td>
                    </tr>
                    <tr>
                      <td>原产地</td><td>{{ item.pickUpInfoVo.countryOfOrigin }}</td>
                    </tr>
                    <tr>
                      <td>HS编码</td><td>{{ item.pickUpInfoVo.hsCode }}</td>
                    </tr>
                    <tr>
                      <td>货物名称</td><td>{{ item.cargoName }}</td>
                    </tr>
                    <tr>
                      <td>箱型</td><td>{{ item.pickUpInfoVo.boxType }}</td>
                    </tr>
                    <tr>
                      <td>件数</td><td>{{ item.pieceNumber }}</td>
                    </tr>
                    <tr>
                      <td>重量（KG）</td><td>{{ item.pickUpInfoVo.actualWeight }}</td>
                    </tr>
                    <tr>
                      <td>总金额</td><td>{{ item.money }}</td>
                    </tr>
                    <tr>
                      <td>操作</td><td><a href="javascript:;" @click="receiptPlanApprovalDetail">查看详情</a></td>
                    </tr>
                  </table>
                </div>
              </van-cell>
            </div>
            <van-cell title="预计到库日期" :value="utils.dateTimeFormat(inWarehousingDetail.expectDateStart)" />
          </van-cell-group>
          <van-cell-group title="发运计划">
            <van-cell title="运输方式" :value="utils.statusFormat(inWarehousingDetail.transportType, 'LogisticsMode')" />
            <van-cell title="车数" :value="utils.isEffectiveCommon(inWarehousingDetail.vehiclesNum)" />
            <van-cell title="发运日期" :value="utils.dateTimeFormat(inWarehousingDetail.shipmentDateStart)" />
            <div class="table goods-table">
              <table class="inner-goods-table">
                <thead>
                  <tr>
                    <th>车牌号</th>
                    <th>联系人</th>
                    <th>联系电话</th>
                    <th>驾驶证号或身份证号</th>
                    <th>备注</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in inWarehousingDetail.vehiclesList" :key="item.materialId">
                    <td>{{ utils.isEffectiveCommon(item.numberPlates) }}</td>
                    <td>{{ utils.isEffectiveCommon(item.contacts) }}</td>
                    <td>{{ utils.isEffectiveCommon(item.phone) }}</td>
                    <td>{{ utils.isEffectiveCommon(item.certificates) }}</td>
                    <td>{{ utils.isEffectiveCommon(item.remarks) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </van-cell-group>
        </van-tab>
        <van-tab name="audit" title="审批意见">
          <workflow-audit v-if="Number($route.query.taskViewMode)!==0" page-key="stock_in_plan" :business-key="inWarehousingDetail.stockInId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:inWarehousingDetail.stockInId,motif:`${inWarehousingDetail.cargoNames}-${inWarehousingDetail.warehouseName}`}" :view-mode="true" @confirmAudit="utils.backReturn($route.query.messageType)" />
          <workflow-audit v-else page-key="stock_in_plan" :business-key="inWarehousingDetail.stockInId" :task-id="$route.query.taskId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:inWarehousingDetail.stockInId,motif:`${inWarehousingDetail.cargoNames}-${inWarehousingDetail.warehouseName}`}" @confirmAudit="utils.backReturn($route.query.messageType)" />
        </van-tab>
      </van-tabs>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="utils.backReturn($route.query.messageType)" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
import WorkflowAudit from '@/components/workflow-audit'
// import fileList from '@/components/file-list'
export default {
  components: {
    WorkflowAudit,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data () {
    return {
      activeName: 'info',
      inWarehousingDetail: {},
      quotaDetail: {},
      qualificationList: [],
      businessLicenseList: [],
      fileBusinessSnImgsList: [],
      accountInfoVoList: []
    }
  },
  created () {
    this.getCompanyDetail()
  },
  methods: {
    // 处理文件数组
    getFileName (fileUrl) {
      const splitList = fileUrl ? fileUrl.split('/') : []
      return splitList[splitList.length - 1] || ''
    },
    getOrderNo () {
      const params = {
        orderId: this.inWarehousingDetail.orderId
      }
      this.api.task.inWarehousing.getOrderNo(params).then(result => {
        this.inWarehousingDetail.orderName = result.data.value[0].orderName
        this.inWarehousingDetail.orderNo = result.data.value[0].orderNo
        this.inWarehousingDetail.contractName = result.data.value[0].contractName
        this.inWarehousingDetail.contractNumber = result.data.value[0].contractNumber
        this.inWarehousingDetail.dsEnterpriseName = result.data.value[0].dsEnterpriseName
        this.inWarehousingDetail.tradeOrgName = result.data.value[0].tradeOrgName
        this.inWarehousingDetail.usEnterpriseName = result.data.value[0].usEnterpriseName
        this.inWarehousingDetail.enterpriseId = result.data.value[0].enterpriseId
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    getCompanyDetail () {
      this.utils.loading()
      this.api.task.inWarehousing.getDetail(this.$route.query.detailId).then(result => {
        this.api.task.inWarehousing.getOrderNo({ orderId: result.data.value.orderId }).then(res => {
          this.inWarehousingDetail = result.data.value
          this.inWarehousingDetail.orderName = res.data.value[0].orderName
          this.inWarehousingDetail.orderNo = res.data.value[0].orderNo
          this.inWarehousingDetail.contractName = res.data.value[0].contractName
          this.inWarehousingDetail.contractNumber = res.data.value[0].contractNumber
          this.inWarehousingDetail.dsEnterpriseName = res.data.value[0].dsEnterpriseName
          this.inWarehousingDetail.tradeOrgName = res.data.value[0].tradeOrgName
          this.inWarehousingDetail.usEnterpriseName = res.data.value[0].usEnterpriseName
          this.inWarehousingDetail.enterpriseId = res.data.value[0].enterpriseId
          if (this.inWarehousingDetail.orgLicenseImgs) {
            this.businessLicenseList = [{
              fileUrl: this.inWarehousingDetail.orgLicenseImgs,
              url: this.inWarehousingDetail.orgLicenseImgs,
              src: this.inWarehousingDetail.orgLicenseImgs,
              fileName: this.getFileName(this.inWarehousingDetail.orgLicenseImgs),
              name: this.getFileName(this.inWarehousingDetail.orgLicenseImgs),
              id: 0
            }]
          } else {
            this.businessLicenseList = []
          }
          if (this.inWarehousingDetail.businessSnPreImgs) {
            this.fileBusinessSnImgsList.push({
              fileUrl: this.inWarehousingDetail.businessSnPreImgs,
              url: this.inWarehousingDetail.businessSnPreImgs,
              fileName: this.getFileName(this.inWarehousingDetail.businessSnPreImgs),
              name: this.getFileName(this.inWarehousingDetail.businessSnPreImgs),
              id: 0
            })
          }
          if (this.inWarehousingDetail.businessSnBackImgs) {
            this.fileBusinessSnImgsList.push({
              fileUrl: this.inWarehousingDetail.businessSnBackImgs,
              url: this.inWarehousingDetail.businessSnBackImgs,
              fileName: this.getFileName(this.inWarehousingDetail.businessSnBackImgs),
              name: this.getFileName(this.inWarehousingDetail.businessSnBackImgs),
              id: 0
            })
          }
        })
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    receiptPlanApprovalDetail () {
      this.$router.push({ name: 'receiptPlanApprovalDetail', query: { detailId: this.inWarehousingDetail.id } })
    },
    preview (file, detail) {
      if (file.fileType) {
        const fileTypeArr = file.fileType.split('/')
        if (fileTypeArr[0] !== 'image') {
          window.open(file.url)
        }
      }
    }
  }
}
</script>

<style scoped>
  .certificate-photo >>> .van-uploader__wrapper {
    display: inline-flex !important;
    flex-wrap: inherit !important;
  }
  .van-common-detail >>> .van-tabs .van-tabs__wrap .van-tabs__nav:first-child .van-tab{
    padding: 0 !important;
  }
</style>
